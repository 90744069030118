import React from "react";
import PropTypes from "prop-types";
import { VideoCard } from "@gonoodle/gn-universe-ui";
import { ImageSourcesType } from "@gonoodle/gn-universe-ui/types";
import { CONTENT_TYPES } from "@gonoodle/gn-universe-analytics-schema/src/constants";

import {
  ROUTE_PREFIX,
  SPONSORED_PARTNER_TYPES,
  IMPRESSION_TYPES,
  AD_PRODUCTS_TYPES,
} from "../../constants";
import { useImpression } from "../../hooks";
import FavoriteButton from "../FavoriteButton";
import Link from "../Link";

export default function VideoTile({
  id,
  slug,
  duration,
  sponsor,
  subtitle,
  title,
  description,
  images,
  referrer,
  impressionsProps,
  testId,
}) {
  const inViewRef = useImpression({
    properties: {
      ...impressionsProps,
      impressionType: IMPRESSION_TYPES.VIDEO_TILE,
      adProductType: AD_PRODUCTS_TYPES.VIDEO_THUMBNAIL,
      title,
    },
    enabled: !!impressionsProps,
  });

  return (
    <VideoCard
      ref={inViewRef}
      duration={duration}
      sponsorExpDate={sponsor && sponsor.expiration}
      subtitle={subtitle}
      testId={testId}
      title={
        <Link
          href={`/${ROUTE_PREFIX.VIDEOS}/${id}/${slug}`}
          className="before:block before:absolute before:inset-0 before:cursor-pointer"
          referrer={referrer}
          events={[
            {
              event: "Link Clicked",
              properties: {
                title,
              },
            },
          ]}
        >
          {title}
        </Link>
      }
      description={description}
      images={images}
      actions={
        <FavoriteButton type={CONTENT_TYPES.VIDEO} id={id} title={title} />
      }
    />
  );
}

VideoTile.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  sponsor: PropTypes.shape({
    expiration: PropTypes.string,
    type: PropTypes.oneOf([
      SPONSORED_PARTNER_TYPES.LONG_TERM_SPONSOR,
      SPONSORED_PARTNER_TYPES.PROMOTIONAL_ACTIVITY,
      "channel",
    ]).isRequired,
  }),
  subtitle: PropTypes.string,
  images: PropTypes.shape({ tile: ImageSourcesType }).isRequired,
  impressionsProps: PropTypes.shape({}),
  linkParams: PropTypes.shape({}),
  testId: PropTypes.string,
  referrer: PropTypes.object,
};
