"use client";

import React from "react";
import { Newsletter as PrimitiveNewsletter } from "@gonoodle/gn-universe-ui";

import { useUser } from "../../contexts/user";
import { useLogEvent } from "../../contexts/Analytics";

export default function Newsletter() {
  const { user, updateUser } = useUser();
  const { logEvent: logNewsletterSignUpEvent } = useLogEvent({
    event: "Newsletter Sign Up",
    options: {
      includeReferrer: false,
      includeSourcePage: true,
    },
  });

  const handleNewsletterSignup = React.useCallback(
    (userInfo) => {
      updateUser(userInfo);
      logNewsletterSignUpEvent();
    },
    [logNewsletterSignUpEvent, updateUser],
  );

  if (!user.isAnonymous && !user.isLoggedIn) {
    return <PrimitiveNewsletter handleSubmit={handleNewsletterSignup} />;
  }

  return null;
}
