import { useQuery } from "@tanstack/react-query";

import api from "../../../api";
import { createLayoutFetchers } from "../helpers";

export default function useDataLoader({ resolver, params = {} } = {}) {
  const clientFetcher = api();
  const layoutFetchers = createLayoutFetchers(clientFetcher);
  const oneHour = 1000 * 60 * 60;

  const { data, isLoading } = useQuery({
    queryKey: [resolver, ...Object.values(params)],
    queryFn: () => layoutFetchers[resolver](params),
    enabled: Boolean(resolver),
    staleTime: oneHour * 4,
  });

  return { data, isLoading };
}
