import React from "react";
import {
  Spotlight as PrimitiveSpotlight,
  Skeleton,
} from "@gonoodle/gn-universe-ui";
import { SECTIONS_TYPES } from "@gonoodle/gn-universe-analytics-schema/src/constants";

import { useDataLoader } from "../../hooks";
import { generateSpotlightPathByType } from "../../../../utils/route";
import { SPOTLIGHT_TYPES, ROUTE_PREFIX } from "../../../../constants";
import { useLogEvent } from "../../../../contexts/Analytics";
import { useRouter } from "../../../../hooks";

function normalizeSpotlights(spotlights = []) {
  const [spotlight] = spotlights;
  const {
    destinationType = SPOTLIGHT_TYPES.TAG,
    description,
    content,
    loopingVideo,
    image,
  } = spotlight;

  const isExternal = destinationType === SPOTLIGHT_TYPES.EXTERNAL;
  const url = isExternal
    ? spotlight.url
    : generateSpotlightPathByType(destinationType, {
        id: content.id,
        slug: content.slug,
      });

  return { url, description, image, loopingVideo, isExternal, destinationType };
}

export default function Spotlight({ templateOptions, fetchers }) {
  const router = useRouter();
  const { title, description, label, theme } = templateOptions;
  const [fetcher] = fetchers;
  const { data: spotlights, isLoading } = useDataLoader(fetcher);
  const spotlight = spotlights ? normalizeSpotlights(spotlights) : {};

  const { logEvent: logSpotlightPressed } = useLogEvent({
    event: "Link Clicked",
    properties: {
      title,
      url: spotlight.url,
      sourceElement: SECTIONS_TYPES.SPOTLIGHT,
      sourceName: title,
    },
    options: {
      includeReferrer: false,
      includeSourcePage: true,
      includeSourcePageType: true,
    },
  });

  const onSpotlightPress = () => {
    logSpotlightPressed();

    if (spotlight.isExternal) {
      router.push(
        `/${ROUTE_PREFIX.EXTERNAL_LINK_DISCLAIMER}?href=${spotlight.url}`,
        undefined,
        {
          sourceElement: SECTIONS_TYPES.SPOTLIGHT,
          sourceName: title,
        },
      );
    } else {
      router.push(spotlight.url, undefined, {
        sourceElement: SECTIONS_TYPES.SPOTLIGHT,
        sourceName: title,
      });
    }
  };

  if (isLoading) {
    return (
      <Skeleton className="bg-gray-500 rounded-lg h-[500px] md:h-[720px] lg:h-[420px]" />
    );
  }

  return (
    <PrimitiveSpotlight
      source={spotlight.loopingVideo}
      fallbackImageSource={spotlight.image}
      title={title}
      description={description}
      onClick={onSpotlightPress}
      buttonLabel={label}
      bgColor={theme.background}
      textTheme={theme.color}
    />
  );
}
