import React from "react";
import PropTypes from "prop-types";
import {
  HeartIcon as HeartIconOutline,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/solid";
import { Pressable, Alert } from "@gonoodle/gn-universe-ui";
import { twMerge } from "tailwind-merge";
import { CONTENT_TYPES } from "@gonoodle/gn-universe-analytics-schema/src/constants";

import { useUser } from "../contexts/user";
import { useLogEvent } from "../contexts/Analytics";
import { useProfile, useFavoriteQuery, useFavoriteMutation } from "../hooks";

export default function FavoriteButton({ id, title, type, variant = "icon" }) {
  const { user } = useUser();
  const { profile } = useProfile();
  const alert = Alert.useAlert();
  const {
    favorite: isFavorited,
    isFavoriteLoading,
    favoriteError,
  } = useFavoriteQuery({ slug: id, type });

  const { logEvent: logAddToFavoritesEvent } = useLogEvent({
    event: "Add to Favorites",
    properties: {
      contentType: type,
      id,
      title,
      gradeId: profile?.grade.id,
    },
    options: {
      includeReferrer: false,
      includeSourcePage: true,
      includeSourcePageType: true,
    },
  });

  const { logEvent: logRemoveFromFavoritesEvent } = useLogEvent({
    event: "Remove from Favorites",
    properties: {
      contentType: type,
      id,
      title,
      gradeId: profile?.grade.id,
    },
    options: {
      includeReferrer: false,
      includeSourcePage: true,
      includeSourcePageType: true,
    },
  });

  const {
    toggleFavoriteState,
    isFavoriteMutationLoading,
  } = useFavoriteMutation(id, type, {
    onSuccess: () => {
      if (isFavorited) {
        logRemoveFromFavoritesEvent();
      } else {
        logAddToFavoritesEvent();
      }
    },
    onError: () => {
      alert.publish("Something went wrong, please try again");
    },
  });

  if (!user.isLoggedIn) {
    return null;
  }

  if (variant === "icon" && favoriteError) {
    return (
      <div className="relative">
        <span className="sr-only">
          something went wrong. Please refresh page.
        </span>
        <ExclamationCircleIcon className="h-md text-gray-500" />
      </div>
    );
  }

  if (variant === "button" && favoriteError) {
    return (
      <div className="flex h-button-md px-sm rounded items-center gap-xs justify-center border border-white">
        <span className="sr-only">
          something went wrong. Please refresh page.
        </span>
        <ExclamationCircleIcon className="h-md text-white" />
      </div>
    );
  }

  return (
    <Pressable
      elementType="button"
      disabled={isFavoriteMutationLoading || isFavoriteLoading}
      className={twMerge(
        "cursor-pointer z-10 relative",
        (isFavoriteMutationLoading || isFavoriteLoading) && "opacity-50",
      )}
      onPress={toggleFavoriteState}
    >
      {variant === "icon" ? (
        <React.Fragment>
          <span className="sr-only">
            {isFavorited ? "Unfavorite" : "Favorite"}
          </span>
          {isFavorited ? (
            <HeartIconSolid className="h-md text-white" />
          ) : (
            <HeartIconOutline className="h-md text-gray-500" />
          )}
        </React.Fragment>
      ) : (
        <div
          className={twMerge(
            "flex h-button-md px-sm rounded items-center gap-xs justify-center",
            isFavorited ? "bg-white" : "border border-white",
          )}
        >
          <span
            className={twMerge(
              "text-sm font-bold",
              isFavorited ? "text-gray-900" : "text-white",
            )}
          >
            {isFavorited ? "Favorited" : "Favorite"}
          </span>
          {isFavorited ? (
            <HeartIconSolid className="h-md text-gray-900" />
          ) : (
            <HeartIconOutline className="h-md text-white" />
          )}
        </div>
      )}
    </Pressable>
  );
}

FavoriteButton.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([CONTENT_TYPES.VIDEO, CONTENT_TYPES.ACTIVITY])
    .isRequired,
  variant: PropTypes.oneOf(["button", "icon"]),
};
