import React from "react";
import PropTypes from "prop-types";
import { PrintableCard } from "@gonoodle/gn-universe-ui";
import { ImageSourcesType } from "@gonoodle/gn-universe-ui/types";
import { CONTENT_TYPES } from "@gonoodle/gn-universe-analytics-schema/src/constants";

import FavoriteButton from "../FavoriteButton";
import { ROUTE_PREFIX } from "../../constants";
import Link from "../Link";

export default function PrintableTile({
  id,
  slug,
  mode,
  sources,
  title,
  description,
  format,
  referrer,
}) {
  return (
    <>
      <PrintableCard.Cover mode={mode} format={format}>
        <PrintableCard.Content sources={sources} />
      </PrintableCard.Cover>
      <div className="flex flex-row mt-xs space-x-sm justify-between items-start">
        <Link
          href={`/${ROUTE_PREFIX.ACTIVITIES}/${id}/${slug}`}
          referrer={referrer}
          events={[
            {
              event: "Link Clicked",
              properties: {
                title,
              },
            },
          ]}
        >
          <PrintableCard.Info
            mode={mode}
            title={title}
            description={format === "landscape" ? description : undefined}
            className="before:block before:absolute before:inset-0 before:cursor-pointer"
          />
        </Link>

        <FavoriteButton type={CONTENT_TYPES.ACTIVITY} title={title} id={id} />
      </div>
    </>
  );
}

PrintableCard.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["dark", "light"]),
  sources: ImageSourcesType.isRequired,
  title: PropTypes.string.isRequired,
  format: PropTypes.oneOf(["landscape", "square"]),
  referrer: PropTypes.object,
};
