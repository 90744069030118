import React from "react";
import PropTypes from "prop-types";
import { AgeGatedPressable, ImageTile } from "@gonoodle/gn-universe-ui";
import { ImageSourcesType } from "@gonoodle/gn-universe-ui/types";

import { useCurriculum, useRouter } from "../../hooks";
import {
  requiresAgeGate,
  transformSuperNoodleMarketingUrlToCurriculum,
} from "../../utils/superNoodleMarketingUrl";
import { URLS } from "../../constants";
import { useLogEvent } from "../../contexts/Analytics";
import Link from "../Link";

export default function CustomLinkCardTile({
  sources,
  title = "",
  description,
  format = "landscape",
  customLinkTitle,
  destinationUrl,
  referrer,
}) {
  const router = useRouter();
  const curriculum = useCurriculum();
  const { logEvent: logLinkClickedEvent } = useLogEvent({
    event: "Link Clicked",
    options: {
      includeReferrer: false,
      includeSourcePage: true,
      includeSourcePageType: true,
    },
  });

  const superNoodleRoute = transformSuperNoodleMarketingUrlToCurriculum(
    destinationUrl,
    curriculum,
  );
  const showAgeGate = requiresAgeGate(destinationUrl, curriculum);

  const customLinkDestinationUrl = superNoodleRoute || destinationUrl;

  return showAgeGate ? (
    <AgeGatedPressable
      className="text-left"
      onPress={() => {
        logLinkClickedEvent({
          title,
        });

        return router.push(URLS.SUPERNOODLE_URL);
      }}
    >
      <ImageTile
        alt={title}
        title={customLinkTitle}
        format={format}
        description={description}
        sources={sources}
      />
    </AgeGatedPressable>
  ) : (
    <Link
      href={customLinkDestinationUrl}
      referrer={referrer}
      events={[
        {
          event: "Link Clicked",
          properties: {
            title,
            url: customLinkDestinationUrl,
          },
        },
      ]}
    >
      <ImageTile
        alt={title}
        title={customLinkTitle}
        format={format}
        description={description}
        sources={sources}
      />
    </Link>
  );
}

CustomLinkCardTile.propTypes = {
  title: PropTypes.string,
  sources: ImageSourcesType.isRequired,
  description: PropTypes.string.isRequired,
  format: PropTypes.oneOf(["landscape", "square", "portrait"]),
  customLinkTitle: PropTypes.string.isRequired,
  destinationUrl: PropTypes.string.isRequired,
  referrer: PropTypes.object,
};
