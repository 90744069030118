import React from "react";
import PropTypes from "prop-types";
import { ImageTile } from "@gonoodle/gn-universe-ui";
import { ImageSourcesType } from "@gonoodle/gn-universe-ui/types";

import { ROUTE_PREFIX } from "../../constants";
import Link from "../Link";

const TextTile = React.forwardRef(function TextTile(
  { id, slug, name, description, tile, title, format, referrer },
  forwardedRef,
) {
  let customTagtitle = "";
  let customTagDescription = "";
  if (format === "landscape") {
    customTagtitle = title;
    customTagDescription = description;
  }

  return (
    <>
      <Link
        href={`/${ROUTE_PREFIX.TAGS}/${id}/${slug}`}
        referrer={referrer}
        events={[
          {
            event: "Link Clicked",
            properties: {
              title: name,
            },
          },
        ]}
      >
        <ImageTile
          ref={forwardedRef}
          alt={name}
          name={name}
          title={customTagtitle}
          sources={tile}
          format={format}
          description={customTagDescription}
        />
      </Link>

      <span className="sr-only absolute left-1/4 uppercase text-white bottom-0">
        {name}
      </span>
    </>
  );
});

export default TextTile;

TextTile.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tile: ImageSourcesType,
  referrer: PropTypes.object,
};
