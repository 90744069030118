/* eslint-disable @next/next/no-img-element */
import React from "react";
import PropTypes from "prop-types";

import { ROUTE_PREFIX } from "../../constants";
import Link from "../Link";

export default function BlogCardTile({
  title,
  name,
  description,
  slug,
  imageUrl,
  referrer,
}) {
  return (
    <Link
      href={`/${ROUTE_PREFIX.COMPANY}/post/${slug}`}
      referrer={referrer}
      events={[
        {
          event: "Link Clicked",
          properties: {
            title,
          },
        },
      ]}
    >
      <div className="overflow-hidden rounded aspect-w-16 aspect-h-9">
        <img
          className="object-cover transform transition-transform group-hover:scale-110 filter drop-shadow rounded"
          src={imageUrl}
          alt={name}
        />
      </div>
      <h3 className="gn-text-sm mt-sm text-white line-clamp-3">{name}</h3>
      <p className="gn-text-xs mt-xs text-gray-500 line-clamp-4">
        {description}
      </p>
    </Link>
  );
}

BlogCardTile.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  slug: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  referrer: PropTypes.object,
};
