import React from "react";
import { twMerge } from "tailwind-merge";
import {
  Skeleton,
  Image,
  BackgroundVideo,
  CallToActionLabel,
  AgeGatedPressable,
} from "@gonoodle/gn-universe-ui";
import {
  SECTIONS,
  HERO_UNIT_ITEM_TYPES,
} from "@gonoodle/gn-universe-analytics-schema/src/constants";
import { PlayIcon, DownloadIcon } from "@heroicons/react/outline";

import {
  CONTENT_TYPES,
  URLS,
  IMPRESSION_TYPES,
  AD_PRODUCTS_TYPES,
} from "../../../../constants";
import { getRoutePrefixByContentType } from "../../../../utils/route";
import { useDataLoader } from "../../hooks";
import Link from "../../../Link";
import {
  useCurriculum,
  useTruncatedText,
  useImpression,
  useRouter,
} from "../../../../hooks";
import {
  requiresAgeGate,
  transformSuperNoodleMarketingUrlToCurriculum,
} from "../../../../utils/superNoodleMarketingUrl";
import { useLogEvent } from "../../../../contexts/Analytics";

const getOriginalItemTitle = ({ contentType, content }) =>
  [CONTENT_TYPES.TAG, CONTENT_TYPES.CHANNEL, CONTENT_TYPES.TOPIC].includes(
    contentType,
  )
    ? content.name
    : content.title;

function PrimaryCard({
  title,
  media,
  sponsorLogo,
  callToAction,
  impressionsProps,
}) {
  const inViewRef = useImpression({
    properties: {
      ...impressionsProps,
      impressionType: IMPRESSION_TYPES.VIDEO_TILE,
      adProductType: AD_PRODUCTS_TYPES.VIDEO_THUMBNAIL,
    },
    enabled: !!impressionsProps,
  });

  return (
    <div
      ref={inViewRef}
      className="flex flex-col bg-purple h-full w-full p-1 rounded-lg"
    >
      <div className="aspect-w-16 aspect-h-9 relative overflow-hidden">
        <BackgroundVideo
          className="w-full h-auto object-contain rounded-t-lg"
          source={media.loopingVideo}
          fallbackImage={{
            sources: media.backgroundImage,
            alt: title,
          }}
        />

        {sponsorLogo && (
          <div className="absolute w-1/5 h-fit ml-auto mt-auto pb-2 pr-2">
            <Image
              className="w-full h-auto object-contain"
              alt="Sponsor logo"
              sources={sponsorLogo}
            />
          </div>
        )}
      </div>

      <div className="bg-purple flex flex-col px-3 pt-6 pb-5 space-y-4 md:flex-row md:justify-between md:items-center md:px-7 md:space-y-0 md:space-x-4">
        <span
          className="font-extrabold text-lg text-white line-clamp-2"
          style={{ textShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)` }}
        >
          {title}
        </span>

        <CallToActionLabel
          variant="light"
          size="md"
          className="min-w-fit max-w-fit"
        >
          {callToAction}
        </CallToActionLabel>
      </div>
    </div>
  );
}

function SecondaryCard({
  title,
  description,
  textTheme,
  image,
  impressionsProps,
}) {
  const textColor = textTheme === "light" ? "text-white" : "text-gray-900";
  const { containerRef, lineClamp } = useTruncatedText();
  const inViewRef = useImpression({
    properties: {
      ...impressionsProps,
      impressionType: IMPRESSION_TYPES.VIDEO_TILE,
      adProductType: AD_PRODUCTS_TYPES.VIDEO_THUMBNAIL,
    },
    enabled: !!impressionsProps,
  });

  return (
    <div
      ref={inViewRef}
      className="flex flex-row h-full space-x-3 lg:flex-col lg:space-x-0 lg:space-y-5"
    >
      <div className="rounded-lg overflow-hidden w-[37%] lg:w-full">
        <div className="aspect-w-16 aspect-h-9">
          <Image
            className="w-full h-auto object-contain"
            sources={image}
            alt={title}
          />
        </div>
      </div>

      <div className={twMerge("flex flex-col flex-1 pr-1", textColor)}>
        <span className="text-sm font-bold line-clamp-2 overflow-hidden lg:text-md">
          {title}
        </span>

        <div
          ref={containerRef}
          className="relative hidden text-sm lg:flex flex-1"
        >
          <p
            className="absolute line-clamp-[var(--line-clamp)] md:mt-1"
            style={{ "--line-clamp": lineClamp }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default function HeroUnit({ fetchers }) {
  const router = useRouter();
  const curriculum = useCurriculum();
  const { logEvent } = useLogEvent({
    event: "Link Clicked",
    options: {
      includeReferrer: false,
      includeSourcePage: true,
      includeSourcePageType: true,
    },
  });
  const [fetcher] = fetchers;
  const { data, isLoading } = useDataLoader(fetcher);
  const heroUnit = Array.isArray(data) ? data[0] : data;

  if (isLoading) {
    return <Skeleton className="rounded-lg bg-gray-500 h-[465px]" />;
  }

  if (!heroUnit) {
    return null;
  }

  const { primaryItem, secondaryItems } = heroUnit;
  const hasSecondaryItems = secondaryItems.length > 0;

  const superNoodleRoute = transformSuperNoodleMarketingUrlToCurriculum(
    primaryItem.content.destinationUrl,
    curriculum,
  );
  const showAgeGate = requiresAgeGate(
    primaryItem.content.destinationUrl,
    curriculum,
  );

  const primaryItemRoute =
    superNoodleRoute ||
    (primaryItem.contentType === CONTENT_TYPES.CUSTOM_LINK
      ? primaryItem.content.destinationUrl
      : `${getRoutePrefixByContentType(primaryItem.contentType)}/${
          primaryItem.content.id
        }/${primaryItem.content.slug}`);

  return (
    <div
      className={twMerge(
        "grid min-w-full bg-white p-5 gap-y-6 -mx-6 md:mx-0 md:rounded-lg lg:grid-cols-[43.5%_1fr] lg:gap-x-14 lg:px-10 lg:gap-y-0",
        !hasSecondaryItems && "gap-y-0",
      )}
    >
      {showAgeGate ? (
        <AgeGatedPressable
          className={twMerge(
            "block h-fit text-left w-full",
            !hasSecondaryItems &&
              "col-span-2 md:place-self-center w-full lg:w-[40%]",
          )}
          onPress={() => {
            logEvent({
              title: getOriginalItemTitle(primaryItem),
            });

            router.push(URLS.SUPERNOODLE_URL);
          }}
        >
          <PrimaryCard
            title={primaryItem.title}
            sponsorLogo={primaryItem.sponsorLogo}
            media={{
              loopingVideo: primaryItem.loopingVideo,
              backgroundImage: primaryItem.backgroundImage,
            }}
            callToAction={
              <div className="flex flex-row justify-center items-center text-center">
                {primaryItem.ctaText}
                {CONTENT_TYPES.VIDEO === primaryItem.contentType && (
                  <PlayIcon className="h-input-icon ml-xs shrink-0" />
                )}

                {CONTENT_TYPES.ACTIVITY === primaryItem.contentType && (
                  <DownloadIcon className="h-input-icon ml-xs shrink-0" />
                )}
              </div>
            }
            impressionsProps={
              primaryItem?.content?.partner?.partnerId
                ? {
                    title: getOriginalItemTitle(primaryItem),
                    partnerId: primaryItem.content.partner.partnerId,
                    partnerName: primaryItem.content.partner.partnerName,
                  }
                : null
            }
          />
        </AgeGatedPressable>
      ) : (
        <Link
          href={primaryItemRoute}
          className={twMerge(
            "block h-fit",
            !hasSecondaryItems &&
              "col-span-2 md:place-self-center w-full lg:w-[40%]",
          )}
          referrer={{
            sourceElement: HERO_UNIT_ITEM_TYPES.PRIMARY,
            sourceName: SECTIONS.HERO_UNIT,
          }}
          events={[
            {
              event: "Link Clicked",
              properties: {
                title: getOriginalItemTitle(primaryItem),
                url:
                  primaryItem.contentType === CONTENT_TYPES.CUSTOM_LINK
                    ? primaryItem.content.destinationUrl
                    : undefined,
              },
            },
          ]}
        >
          <PrimaryCard
            title={primaryItem.title}
            sponsorLogo={primaryItem.sponsorLogo}
            media={{
              loopingVideo: primaryItem.loopingVideo,
              backgroundImage: primaryItem.backgroundImage,
            }}
            callToAction={
              <div className="flex flex-row justify-center items-center text-center">
                {primaryItem.ctaText}
                {CONTENT_TYPES.VIDEO === primaryItem.contentType && (
                  <PlayIcon className="h-input-icon ml-xs shrink-0" />
                )}

                {CONTENT_TYPES.ACTIVITY === primaryItem.contentType && (
                  <DownloadIcon className="h-input-icon ml-xs shrink-0" />
                )}
              </div>
            }
            impressionsProps={
              primaryItem?.content?.partner?.partnerId
                ? {
                    title: getOriginalItemTitle(primaryItem),
                    partnerId: primaryItem.content.partner.partnerId,
                    partnerName: primaryItem.content.partner.partnerName,
                  }
                : null
            }
          />
        </Link>
      )}

      <div className="grid gap-y-5 md:grid-cols-2 md:gap-x-5 lg:grid-rows-1 lg:grid-cols-4">
        {secondaryItems.map((item, itemIndex) => {
          const secondarySuperNoodleRoute = transformSuperNoodleMarketingUrlToCurriculum(
            item.content.destinationUrl,
            curriculum,
          );
          const secondaryShowSuperNoodleRoute = requiresAgeGate(
            item.content.destinationUrl,
            curriculum,
          );

          const secondaryItemRoute =
            secondarySuperNoodleRoute ||
            (item.contentType === CONTENT_TYPES.CUSTOM_LINK
              ? item.content.destinationUrl
              : `${getRoutePrefixByContentType(item.contentType)}/${
                  item.content.id
                }/${item.content.slug}`);

          return secondaryShowSuperNoodleRoute ? (
            <AgeGatedPressable
              key={`${item.content.id}_${itemIndex}`}
              className="text-left"
              onPress={() => {
                logEvent({
                  title: getOriginalItemTitle(item),
                });

                router.push(URLS.SUPERNOODLE_URL);
              }}
            >
              <SecondaryCard
                title={item.title}
                description={item.description}
                image={item.image}
                impressionsProps={
                  item?.content?.partner?.partnerId
                    ? {
                        title: getOriginalItemTitle(item),
                        partnerId: item.content.partner.partnerId,
                        partnerName: item.content.partner.partnerName,
                      }
                    : null
                }
              />
            </AgeGatedPressable>
          ) : (
            <Link
              key={`${item.content.id}_${itemIndex}`}
              href={secondaryItemRoute}
              className="block self-stretch"
              referrer={{
                sourceElement: HERO_UNIT_ITEM_TYPES.SECONDARY,
                sourceName: SECTIONS.HERO_UNIT,
              }}
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: getOriginalItemTitle(item),
                    url:
                      item.contentType === CONTENT_TYPES.CUSTOM_LINK
                        ? item.content.destinationUrl
                        : undefined,
                  },
                },
              ]}
            >
              <SecondaryCard
                title={item.title}
                description={item.description}
                image={item.image}
                impressionsProps={
                  item?.content?.partner?.partnerId
                    ? {
                        title: getOriginalItemTitle(item),
                        partnerId: item.content.partner.partnerId,
                        partnerName: item.content.partner.partnerName,
                      }
                    : null
                }
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
}
