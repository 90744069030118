import React from "react";
import {
  HeroVideo as PrimitiveHeroVideo,
  Skeleton,
} from "@gonoodle/gn-universe-ui";
import {
  SECTIONS_TYPES,
  SECTIONS,
} from "@gonoodle/gn-universe-analytics-schema/src/constants";

import { useDataLoader } from "../../hooks";
import { ROUTE_PREFIX } from "../../../../constants";
import { createImgSources } from "../../../../utils/imgSources";
import Link from "../../../Link";

export default function HeroVideo({ templateOptions, fetchers }) {
  const [fetcher] = fetchers;
  const { title, description } = templateOptions;
  const { data, isLoading } = useDataLoader(fetcher);
  const heroVideo = Array.isArray(data) ? data[0] : data;

  if (isLoading || !heroVideo) {
    return (
      <Skeleton className="rounded-lg bg-gray-500 h-[375px] md:h-[405px] lg:h-[675px]" />
    );
  }

  return (
    <Link
      href={`/${ROUTE_PREFIX.VIDEOS}/${heroVideo.id}/${heroVideo.slug}`}
      referrer={{
        sourceElement: SECTIONS_TYPES.HERO,
        sourceName: heroVideo.type === "rotation" ? SECTIONS.ROTATION : title,
      }}
    >
      <PrimitiveHeroVideo
        title={title}
        description={description}
        source={heroVideo.loopingVideo}
        fallbackImageSource={createImgSources(heroVideo.images.heroImage)}
        heroLogo={createImgSources(heroVideo.images.logo)}
        buttonText={heroVideo.buttonText}
        mode={heroVideo.textTheme}
      />
    </Link>
  );
}
